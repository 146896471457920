.personContainer {
  display: flex;
  flex-direction: column;
  background: #F6F9F9;
  height: 100%;

  .navigate {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .headImageUpload {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
  }
}
.header {
  width: 100%;
  height: .62rem;
  display: flex;
  flex-direction: row;
  background: rgb(255, 255, 255);
  margin-bottom: .1rem;
  
  p {
    font-size: .18rem;
    font-weight: bold;
    align-self: center;
    padding-left: 5.07%;
  }
}
