.container{
  min-height: 100%;
  padding: 10px 18px;
  background-color: #fff;

  pre{
    white-space: pre-wrap;
  }

  h1{
    font-size:16px;
    padding: 25px 0;
    font-weight:bold;
    color:#333;
    line-height:23px;
  }

  p{
    padding-bottom: 10px;
    font-size:14px;
    font-weight:500;
    color:#333;
    line-height:20px;
  }

  .pic{
    background-color: #F9F9F9;
    padding: 10px;
    border-radius: 5px;
    img{
      width: 100%;
      // max-height: 2.1rem;
    }
  }
}