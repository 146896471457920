.container {
  min-height: 100%;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 29px;
    padding-bottom: 21px;
    background-color: #fff;
    border-bottom: 1px solid #eee;

    h1 {
      font-size: 18px;
      font-weight: bold;
      color: rgba(119, 199, 214, 1);
      line-height: 18px;
      padding-bottom: 9px;
    }

    h2 {
      font-size: 13px;
      font-weight: 500;
      color: rgba(246, 205, 174, 1);
      line-height: 18px;
    }
  }

  ul {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    li {
      width: 48%;
      padding: 8px;
      background-color: #fff;
      margin-bottom: 10px;

      img{
        width: 100%;
        height:100px;
      }

      h2 {
        font-size: 14px;
        color: #333;
        line-height: 18px;
        text-align: center;
        padding: 2px;
      }
    }
  }
}