.container{
  background-color: #fff;
  min-height: 100%;

 
  .banner{
    padding: 10px;
    background-color: #F8F9FC;
    width: 100%;
  }

  .courseInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-bottom: 26px;

    &>*{
      width: 100%;
    }

    .title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      h1{
        font-weight: bold;
        color: #333333;
      }

      p{
        font-weight: bold;
        color: #F41F1F;
      }
    }

    .courseStatus{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size:12px;
      color:#666;
      line-height:18px;
      padding: 15px 0;

      &>span:last-child{
        padding: 2px 5px;
        background-color: #E6FBFF;
        font-size: 11px;
        color: #77c7d6;
        border-radius: 2px;
      }
    }

    .courseOverview{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      font-size:12px;
      color:#666;
      line-height:18px;

      &>p:first-child{
        white-space: nowrap;
      }
      pre{
        white-space: pre-wrap;
      }
    }

    button{
      width: 95%;
      height: .48rem;
      background-color: #77c7d6;
      border-radius: .24rem;
      color: #fff;
      border: 0;
      outline: 0;
      margin-top: 16px;
    }
  }

  .tabsContainer{
    border-top: 1px solid #eee;
  }

  .courseIntro{
    padding: 0 18px;
    background-color: #fff;

    .courseOutline{
      padding-top: 30px;
      padding-bottom: 11px;
      border-bottom: 1px solid #EEEEEE;
      h1{
        color: #333333;
        font-size: 14px;
        font-weight: bold;
      }
      pre{
        font-size: 14px;
        color: #666;
        padding: 13px 0;
        white-space: pre-wrap;
      }
    }

    .teacher{
      padding-top: 23px;
      padding-bottom: 20px;
      

      h1{
        font-size:14px;
        font-weight:bold;
        color:#333;
        line-height:18px;
        padding-bottom: 10px;
      }

      .teacherList{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &>div{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 6px;
          border: 1px solid #EDEDED;
          width: 48%;
          // border-radius: 3px;
          box-shadow: 0 0 3px #ddd;
          img{
            width: 31.5%;
          }

          .content{
            padding-left: 10px;
            h2{
              font-size:15px;
              font-weight:bold;
              color:#333;
              line-height:18px;
            }

            p{
              font-size:12px;
              color:#999;
              line-height:18px;
            }
          }
        }
      }
    }
  }

  /*课程表*/
  .timetable{
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    &>*{
      width: 100%;
    }
    h1{
      font-size:14px;
      font-weight:bold;
      color:#333;
      line-height:18px;
      padding-top: 30px;
      padding-bottom: 9px;
    }

    ul{
      li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;
        // padding: 15px 0;
        font-size: 14px;
        color: #333;

        .name{
          flex: 1;
          // padding-left: 15px;
          padding:15px;
        }

        .icon{
          padding: 10px;
          i{
            font-size: 20px;
            color: #77c7d6;
          }

          span{
            color: #F3641E;
            // padding-right: 5px;
          }
        }
      }
    }

    button{
      margin:18px; 
      color:#77c7d6;
      font-size: 15px; 
    }
  }

  .coursePlan{
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding-bottom: 30px;
    &>*{
      width: 100%;
    }
    h1{
      font-size:14px;
      font-weight:bold;
      color:#333;
      line-height:18px;
      padding-top: 29px;
      padding-bottom: 11px;
    }

    .planList{
        .planItem{
          padding: 14px 0;
        }

        .info{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 5px;
          color: #333333;
          &>:nth-child(2){
            flex: 1;
            padding-left: 20px;
          }
        }

        .progressContainer{
          height: 10px;
          position: relative;
          width: 100%;
          background-color: #eee;
          border-radius: 5px;

          .progress{
            position: absolute;
            height: 10px;
            width: 20%;
            background-color: #F3641E;
            border-radius: 5px;
          }
        }
    
    }
  }
}