div.container {
  // padding-top: 24px;
  background-color: rgb(40, 206, 151) !important;
  background-image: url('./../../../static/img/首页/footer_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 !important;

  .title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    color: #fff;
    padding: 21px 23px 17px 23px;

    h1 {
      font-size: .18rem;
      font-weight: bold;
      padding-right: .09rem;
    }

    h2 {
      font-size: .12rem;
      font-weight: 500;
    }
  }

  .navLists {
    padding: 18px 23px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    // justify-content: space-between;
    justify-content: flex-start;
    font-size: 15px;
    color: #fff;
   

    li{
      width: 25%;
      padding: 0;
      padding-bottom: 10px;
      color: #fff;
      border: 0;
      background-color: transparent;
      margin: 0;
    }
  }
}

div.container.white {
  background-color: #fff !important;

  .title {
    background-color: #fff;
    color: #333;
  }

  .navLists {
    background-color: #77C7D6;
  }
}