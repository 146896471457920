.rollcallContainer {
  min-height: 100%;
  background-color: rgba(246, 249, 249, 1);
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: .67rem;
    padding-left: 5.73%;
    padding-right: 6%;
    background-color: #fff;
    margin-bottom: .1rem;

    h1 {
      font-size: 18px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: rgba(51,51,51,1);
    }

    .dateWrap {    
      display: inherit;        
      font-size: 12px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(153,153,153,1);
    }
  }

  .content {
    flex: 2;
  }

  .buttonWrap {
    width: 100%;
    text-align: center;
    margin: .25rem 0;
  }
}