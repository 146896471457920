.faqsDetailsContainer {
  height: 100%;
  min-height: 100%;
  background-color: rgba(244, 246, 249, 1);
  padding: .11rem 2.67% .17rem 2.67%;

  .faq {
    min-height: 100%;
    background-color: #fff;
    border-radius: .05rem;
    padding: .18rem 5.21% .22rem 4.23%;
    
    .question {
      h1 {
        padding-bottom: .11rem;
        font-size: 15px;
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        color: rgba(73,85,94,1);
      }

      .date {
        padding-bottom: .17rem;
        font-size: 12px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(153,153,153,1);
      }

      .questionInfo {
        padding-bottom: .31rem;
        font-size:12px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        line-height: 16px;
        color: rgba(153,153,153,1);
      }
    }
  }

  .answer {
    width: 100%;

    p {
      padding-bottom: .11rem;
      font-size: 15px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(51,51,51,1);
    }

    .textarea {
      width: 100%;
      height: 1.51rem;
      background-color: rgba(244, 246, 249, 1);
      border: none;
      border-radius: .05rem;
      resize: none;
      padding: 10px;
  
      &::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
        color: rgba(204,204,204,1);
        font-size: 12px;
        line-height: 1.51rem;
        text-align: center;
      }
      
      &:-moz-placeholder, textarea:-moz-placeholder {
        color: rgba(204,204,204,1);
        font-size: 12px;
        line-height: 1.51rem;
        text-align: center;
      }
      
      &::-moz-placeholder, textarea::-moz-placeholder {
        color: rgba(204,204,204,1);
        font-size: 12px;
        line-height: 1.51rem;
        text-align: center;
      }
      
      &:-ms-input-placeholder, textarea:-ms-input-placeholder {
        color: rgba(204,204,204,1);
        font-size: 12px;
        line-height: 1.51rem;
        text-align: center;
      }
    }
  }

  .buttonWrap {
    text-align: center;
    height: .49rem;
    margin-top: .23rem;
  
    span {
      line-height: .49rem;
    }
  }
}