.container{
  min-height: 100%;
  background-color: rgba(246, 249, 249, 1);
  text-align: center;

  h3 {
    height: 15px;
    font-size: 15px;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: rgba(51,51,51,1);
    line-height: 18px;
    padding-top: 1.04rem;
  }

  img {
   padding-top: .26rem; 
   width: 1.43rem;
  //  height: 1.43rem;
  }
}