.inputWrap {
  position: relative;

  .input {
    width: 100%;
    height: .44rem;
    border: none;
    background-color: rgba(246, 249, 249, 1);
    padding: 16px;
    font-size: 13px;

    &::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
      color: rgba(204,204,204,1);
      font-size: 13px;
    }
    
    &:-moz-placeholder, textarea:-moz-placeholder {
      color: rgba(204,204,204,1);
      font-size: 13px;
    }
    
    &::-moz-placeholder, textarea::-moz-placeholder {
      color: rgba(204,204,204,1);
      font-size: 13px;
    }
    
    &:-ms-input-placeholder, textarea:-ms-input-placeholder {
      color: rgba(204,204,204,1);
      font-size: .13rem;
    }
  }

  .searchIcon {
    position: absolute;
    right: 5.45%;
    top: .13rem;
  }
}