.aboutContainer {
  min-height: 100%;
  background-color: rgba(246, 249, 249, 1); 

  header {
    height: 1.93rem;
    width: 100%;
    margin-bottom: .1rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    background-color: #fff;
    font-size:15px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: rgba(51,51,51,1);
    line-height: 14px;
    align-items: center;
    
    li {
      border-bottom: 1px solid rgba(246, 249, 249, 1);
      display: flex;
      justify-content: space-between;
    }
    
    .contact {
      height: .49rem;
      padding: .17rem 5%;
    }

    .address {
      height: .85rem;
      display: inherit;
      padding: .15rem 5.07%;

      p {

        &:first-child {
          padding-bottom: .09rem;
        }

        &:last-child {
          padding-bottom: .16rem;
          font-size: 14px;
        }
      }
    }

    .qqNumbe {
      height: .49rem;
    }
  }
}
