.myCourseContainer {
  background-color: rgba(246, 249, 249, 1);
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: .62rem;
    display: flex;
    flex-direction: row;
    background: rgb(255, 255, 255);
    margin-bottom: .08rem;

    p {
      font-size: 18px;
      font-weight: bold;
      align-self: center;
      padding-left: 5.07%;
    }
  }

  .courseListWrap {
    box-sizing: content-box;
    padding: 0 2.67%;
    flex: 1;
    .course {
      position: relative;
      display: flex;
      background-color: #fff;
      margin-bottom: 10px;
      padding: 10px;
      height: 67px;

      img {
        // width: 100%;
        width: 110px;
        height: auto;
        // max-height: 67px;
      }

      .courseInfo {
        // display: flex;
        // flex-shrink: 1;
        // flex-direction: column;
        // justify-content: space-between;
        padding-left: 3.66%;
        // flex-basis: 61.97%;

        .courseTitle {
          width: 80%;
          font-size: 15px;
          font-family: PingFang-SC-Bold;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .courseDate {
          font-size: 12px;
          color: rgba(170, 170, 170, 1);
          padding: 3px 0;
        }

        .courseIntro {
          font-size: 11px;
          color: #666666;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */

        }
      }
    }
  }

  .courseStatus {
    position: absolute;
    top: .1rem;
    right: 2%;
    display: inline-block;
    width: 46px;
    height: 16px;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    background-color: rgba(230, 251, 255, 1);
    color: rgba(119, 199, 214, 1);
  }

  .updating {
    background-color: rgba(255, 241, 223, 1);
    color: rgba(244, 165, 62, 1);
  }
}