.container{
  background-color: #fff;
  min-height: 100%;
  .videoContainer{
    height: 230px;
    width: 100%;
    background-color: #000;
    video{
      width: 100%;
      height: 100%;
    }
  }

  .courseInfo{
    // display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-bottom: 26px;

    &>*{
      width: 100%;
    }

    .title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      h1{
        font-weight: bold;
        color: #333333;
      }

      p{
        font-weight: bold;
        color: #F41F1F;
      }
    }

    .courseStatus{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size:12px;
      color:#666;
      line-height:18px;
      padding: 15px 0;

      &>span:last-child{
        padding: 2px 5px;
        background-color: #E6FBFF;
        font-size: 11px;
        color: #77c7d6;
        border-radius: 2px;
      }
    }

    .courseOverview{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      font-size:12px;
      color:#666;
      line-height:18px;

      &>p:first-child{
        white-space: nowrap;
      }
      pre{
        white-space: pre-wrap;
      }
    }

    button{
      width: 95%;
      height: .48rem;
      background-color: #77c7d6;
      border-radius: .24rem;
      color: #fff;
      border: 0;
      outline: 0;
      margin-top: 16px;
    }
  }

    /*课程表*/
    .timetable{
      padding: 0 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
  
      &>*{
        width: 100%;
      }
      h1{
        font-size:14px;
        font-weight:bold;
        color:#333;
        line-height:18px;
        padding-top: 30px;
        padding-bottom: 9px;
      }
  
      ul{
        overflow: auto;
        li{
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #eee;
          // padding: 15px 0;
          font-size: 14px;
          color: #333;
  
          .name{
            flex: 1;
            // padding-left: 15px;
            padding:15px;
          }
  
          .icon{
            padding: 10px;
            i{
              font-size: 20px;
              color: #77c7d6;
            }
  
            span{
              color: #F3641E;
              // padding-right: 5px;
            }
          }
        }
      }
  
      button{
        margin:18px; 
        color:#77c7d6;
        font-size: 15px; 
      }
    }
}