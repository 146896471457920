.capacityAssessContainer {
  background-color: rgba(246, 249, 249, 1);
  min-height: 100%;
  padding-bottom: .15rem;
  .header {
    width: 100%;
    height: .62rem;
    display: flex;
    flex-direction: row;
    background: rgb(255, 255, 255);
    margin-bottom: .08rem;
    
    p {
      font-size: .18rem;
      font-weight: bold;
      align-self: center;
      padding-left: 5.07%;
    }
  }

  .infoWrap {
    margin-bottom: .1rem;

    .radioInputWrap {

      label {
        font-size: 14px;
      }

      .radioInput[type="radio"] + label::before {
        content: "\a0"; /*不换行空格*/
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        width: 18px;
        height: 18px;
        margin-right: .4em;
        margin-top: -2px;
        border-radius: 50%;
        border: 1px solid rgba(204, 204, 204, 1);
        background-clip: content-box;
        background-color: rgba(204, 204, 204, 1);
        padding: .1em;
        line-height: 1; 
      }

      .radioInput[type="radio"]:checked + label::before {
        background-color: rgba(119, 199, 214, 1);
        background-clip: content-box;
        padding: .1em;
        border-color: rgba(119, 199, 214, 1);
      }

      .radioInput[type="radio"]:checked + label {
        color: black;
      }

      .radioInput[type="radio"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
      }      
    }
  }

}
.buttonWrap {
  text-align: center;
  height: .49rem;
  margin-top: .19rem;
  span {
    line-height: .49rem;
  }
}