/*我要咨询*/

.container {
  padding: 10px;
  background-color: #fff;
  min-height: 100%;

  textarea {
    background-color: #F1F5F6;
    border-radius: 5px;
    border: 0;
    width: 100%;
    margin-bottom: 18px;
    resize: none;
    height: 2.04rem;
    padding: 10px;
  }
}