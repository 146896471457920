.container{
  min-height: 100%;
  display: flex;
  background-color: #fff;
  .success{
    flex: 1;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;

    padding: 0 26px;

    &>div{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: .72rem;

      span{
        font-size: 56px;
        color: #3BBB5C;
      }

      h1{
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: .39rem;
      }
    }

    p{
      line-height:25px;
      font-size: .13rem;
      color: #333;
      font-weight: 500;
    }
  }

  .falid{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    padding: 0 26px;

    &>div{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: .72rem;

      span{
        font-size: 56px;
        color: #666666;
      }

      h1{
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: .39rem;
      }
    }

    p{
      line-height:25px;
      font-size: .13rem;
      color: #333;
      font-weight: 500;
    }

    button{
      background:rgba(119,199,214,1);
      border:1px solid rgba(119,199,214,1);
      border-radius:24px;
      width: 50%;
      height: .49rem;
      color: #fff;
      margin-top: 107px;

    }
  }
}