.container {
  img.bannerItem {
    width: 100%;
    height: 185px;
  }

  .intro {
    padding: 18px;
    background-color: #fff;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 13px;

      img {
        width: 73px;
        height: 73px;
        border-radius: 50%;
        background-color: aquamarine;
      }

      &>div {
        display: flex;
        align-items: center;

        .name {
          padding-left: 17px;

          h1 {
            font-size: 18px;
            font-weight: bold;
            color: #333;
            line-height: 18px;
          }

          h2 {
            font-size: 11px;
            color: rgba(102, 102, 102, 1);
            line-height: 18px;
          }
        }
      }
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // width: 50%;
      padding: 18px 0;

      li {
        width: 50%;
        font-size: 13px;
        color: #333;
        line-height: 24px;
      }
    }
  }

  .title {
    display: flex;
    color: #76C6D5;
    padding: 20px 0;

    h1 {
      font-size: 15px;
      font-weight: bold;
      line-height: 18px;
    }

    h2 {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .otherInfo {
    padding: 10px 13px;
    background-color: #fff;

    &>div {
      border-bottom: 1px solid #efefef;
      padding-bottom: 16px;

      &>p {
        font-size: 14px;
        color: rgba(102, 102, 102, 1);
        line-height: 22px;
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      li {
        width: 30%;
        text-align: center;
        color: #fff;
        padding: .16rem 0px;
        margin-bottom: 10px;

        h1 {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
        }

        h2 {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }

    .faculty {
      li {
        background-color: #76C6D5;
      }
    }

    .major {
      .title {

        h1,
        h2 {
          color: #F6CDAE;
        }
      }

      li {
        background-color: #F6CDAE;
      }

      border-bottom: 0;
    }
  }
}