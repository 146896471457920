.container{
    box-sizing: border-box;
    padding: .30rem;
    background-color: #fff;
    min-height: 100vh;
    .msgBox{
        box-sizing: border-box;
        padding-bottom: 20px;
        border-bottom:1px solid #e8e8e8;
        .msgItem{
            margin-top: 20px;
            font-family: 'PingFang-SC-Medium';
            color: #333333;
            font-size: 12px;
        }
    }
    .title{
        margin-top: 20px;
        font-size: 12px;
        font-weight: 600;
        font-family: 'PingFang-SC-Medium';
        color: #333333;
        margin-bottom: 20px;
    }
}