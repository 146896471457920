.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 34px;
  background-color: #fff;
  min-height: 100%;

  .title{
    font-size:16px;
font-weight:bold;
color:rgba(51,51,51,1);
line-height:23px;
  }

  .time{
    font-size:12px;
font-weight:500;
color:rgba(153,153,153,1);
line-height:23px;
padding-top: 12px;
  }

  .pic{
    padding: 18px 4px;
  }

  .content{
    font-size:15px;

font-weight:500;
color:rgba(51,51,51,1);
line-height:24px;
  }
}