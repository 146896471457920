.reimbursementContainer {
  min-height: 100%;
  background-color: rgba(246, 249, 249, 1);
  display: flex;
  flex-direction: column;

  .header {
    height: .62rem;
    padding-top: .23rem;
    padding-left: 5.07%;
    background-color: #fff;
    margin-bottom: .1rem;

    h1 {
      font-size: 18px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: rgba(51,51,51,1);
    }
  }

  .content {
    flex: 2;
  }

  .buttonWrap {
    text-align: center;
    height: .49rem;
    width: 100%;
    margin-bottom: .25rem;
  
    span {
      line-height: .49rem;
    }
  }
}