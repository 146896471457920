html {
  height: 100%;
}

body,
#root {
  height: inherit;
  font-family: Microsoft Yahei, FZHTJW, PingFang SC, sans-serif;
}
@font-face {
  font-family: FZHTJW;
  src: url("../static/FZHTJW.ttf");
}
