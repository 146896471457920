.loginContainer {
  height: 100%;
  padding-top: .17rem;
  background-color: #fff;
}

.header {
  font-size: 14px;
  display: flex;
  justify-content: space-between;

  span {
    display: inline-block;
    color: #999999;
  }

  .logon {
    margin-left: 5.6%;
  }

  .enteranceSwitch {
    margin-right: 5.07%;
    align-self: flex-end;
  }
}

.logoWrap {
  width: 25.33%;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: .4rem;
}

.loginWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #aaa;
}

.warpBtn {
  display: inherit;
  font-size: 14px;
  width: 45%;
  justify-content: space-between;
  padding-bottom: .15rem;

  p {
    min-width: 76px;
    padding: 10px;
  }
}

.formGroup {
  width: 74.4%;
}

.formItem {
  height: 45px;
  display: flex;
  border: 1px solid rgba(227, 227, 227, 1);
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-bottom: .1rem;

  input {
    flex: 1;
    height: 43px;
    border: 0;
    outline: 0;

    &::placeholder {
      font-size: 14px;
      color: #aaa;
    }
  }

  p {
    padding: 10px;

    i {
      font-size: 20px;
      color: #76C6D5;
    }
  }

  button {
    font-size: 12px;
    color: #7DC9D7;
    border:0;
    outline: 0;
    white-space: nowrap;
    background-color: #fff;
  }
}

.btnContainer {
  width: 74.4%;
  padding-top: .17rem;
}

.forget {
  display: flex;
  justify-content: center;
  color: #aaa;
  padding-top: .1rem;
  font-size: 12px;
}

.quickLogin {
  padding-top: .3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 12px;
    color: #aaa;
  }

  button {
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    border: 0;

    i {
      font-size: .3rem;
    }
  }
}

.quickLoginBtnGroup {
  padding-top: .2rem;

  >:first-child {
    background: rgba(231, 246, 249, 1);
    margin-right: 30px;

    i {
      color: #39D494;
    }
  }

  >:last-child {
    background: rgba(231, 246, 249, 1);

    i {
      color: #7AA8F4;
    }
  }
}
