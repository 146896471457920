.myCourseContainer {
  display: flex;
  flex-direction: column;
  background-color: rgba(246, 249, 249, 1);
  width: 100%;
  min-height: 100%;

  .header {    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    height: .91rem;
    margin-bottom: .1rem;

    &>p {
      font-size: .18rem;
      font-weight: bold;
      padding-left: 5.07%;
      padding-top: .23rem;
    }

    .tableHeaderWrap{
      display: flex;
      justify-content: space-around;
      padding-bottom: .16rem;
      width: 100%;

      .tableHeader {
        color: rgba(51, 51, 51, 1);
        font-size: .13rem;
        font-weight: normal;
      }
    }
  }

  .examListWrap {
    background-color: rgba(246, 249, 249, 1);
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin-bottom: .18rem;

    .examInfo {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: .49rem;
      background-color: #fff;
      margin-bottom: .01rem;

      &>p {
        flex: 1;
        font-size: .12rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(153,153,153,1);
        text-align: center;
      }
    }
  }

  .buttonWrap {
    text-align: center;
    height: .49rem;
    margin-bottom: .19rem;
  
    span {
      line-height: .49rem;
    }
  }
}
