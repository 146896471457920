.container{
  min-height: 100%;
  padding: 28px 20px;
  background-color: #fff;

  .title{
    font-size: .16rem;
    line-height: .23rem;
    font-weight: bold;
    color: #333;
  }
  .content{
    font-size:.13rem;
    color: #333;
    line-height:.2rem;
    padding-top: 26px;
  }
}