.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .interview {
    box-sizing: border-box;
    padding: 18px 35px;
    min-height: 100vh;
    padding-bottom: 152px;
  .interview_title {
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      margin: 66px;
      margin: 48px 0;
    }
    .interview_content {
      font-size: 13px;
      line-height: 2;
      margin-bottom: 46px;
      white-space: pre-wrap;
      // text-indent: 2em;
    }
    .interviewPic{
      border: 10px solid #eeeeee;
      border-radius: 5px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .navigate{
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
}
