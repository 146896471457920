.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: .15rem;
    background-color: #fff;
    box-shadow: 0px -2px 10px #333;
    position: relative;

    .base {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 18px;

      img {
        width: .5rem;
        height: .5rem;
        background-color: #ddd;
      }

      div {
        flex: 1;
        padding-left: 15px;

        h1 {
          font-size: 15px;
          font-weight: bold;
          color: #333;
          line-height: 18px;
        }

        p {
          font-size: 12px;
          font-weight: 500;
          color: rgba(153, 153, 153, 1);
          line-height: 18px;
        }
      }
    }

    .courseList {
      display: flex;
      flex-direction: row;
      padding-top: 9px;
      flex-wrap: wrap;
      p {
        width: auto;
        height: 26px;
        background: rgba(246, 205, 174, 1);
        text-align: center;
        color: #fff;
        line-height: 26px;
        border-radius: 2px;
        margin-right: 5px;
        padding: 0 13px;
        margin-bottom: 10px;
      }
    }

    h2 {
      font-size: 14px;
      font-weight: bold;
      color: #333;
      line-height: 18px;
    }

    ul {
      padding-top: 9px;

      display: flex;
      flex-direction: row;

      li {
        background-color: #F6CDAE;
        color: #fff;
        font-size: .12rem;
        padding: .08rem .13rem;
        margin-right: 5px;
      }
    }
  }

  .teacher_details {
    padding: 13px;
    background-color: #fff;

    li {
      border-bottom: 1px solid #efefef;
      padding-top: 18px;

      p {
        padding: 15px 5px;
        font-size: 14px;

        font-weight: 500;
        color: #666;
        line-height: 22px;
      }

      &>div {
        display: flex;
        color: #76C6D5;
        align-items: flex-end;

        h1 {
          font-size: .15rem;
          font-weight: bold;
          line-height: 18px;
        }

        h2 {
          font-size: .12rem;
          font-weight: 500;
          line-height: 18px;
        }
      }

      video {
        width: 100%;
        background-color: #000;
        // margin-top: 17px;
      }
    }
  }

  .teacher_consult {
    padding: 0 13px;
    margin-top: 10px;
    background-color: #fff;
    flex: 1;

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 14px 0;
      border-bottom: 1px solid #efefef;

      h1 {
        font-size: 15px;
        font-weight: bold;
        color: #333;
        line-height: 18px;
      }

      button {
        width: 87px;
        height: 33px;
        background: rgba(130, 202, 216, 1);

        color: #fff;
        border: 0;
        outline: 0;
        border-radius: 3px;
      }
    }

    ul {
      padding: 15px 0;

      li {
        font-size: 13px;
        font-weight: 500;
        color: #333;
        line-height: 44px;
      }
    }
  }

  .videoContainer {
    padding: 5px;
    margin-top: 15px;
    background-color: #82CAD8;

    img,
    video {
      // height: 100%;
      height: 2.02rem;
      width: 100%；
    }
  }
}