.consultListContainer {
  background-color: rgba(246, 249, 249, 1);
  min-height: 100%;

  .header {
    height: .44rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    margin-bottom: .1rem;

    p {
      font-size: 15px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: rgba(153, 153, 153, 1);
      line-height: 18px;
      height: .44rem;
      line-height: .44rem;
      width: 50%;
    }
    
    .waitReply {
      padding-left: 27.73%;
    }
    
    .hasReplyed {
      padding-right: 28.53%;
      text-align: right;
    }
    
    .active {
      color: rgba(119,199,214,1);    
    }
  }

  .content {
    
  }
}