.container {
  min-height: 100%;
  padding: 28px 18px;
  background-color: #fff;

  .baseInfo {
    &>* {
      padding-bottom: 17px;
    }

    h1 {
      font-size: .16rem;
      font-weight: bold;
      color: #333;
      line-height: .18rem;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: #333;
      line-height: 18px;
    }
  }

  h2 {
    font-size: .14rem;
    font-weight: bold;
    color: #333;
    line-height: .18rem;
  }

  &>div {
    p {
      font-size: .13rem;
      font-weight: 500;
      color: #333;
      line-height: .18rem;
    }

    &>* {
      padding-bottom: 17px;
    }
  }

}