/*支付页面*/
.container{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  header{
    background-color: #fff;
    display: flex;
    padding: 10px;
    margin-bottom: 10px;

    .pic{
      width: 33%;

      img{
        height: 100%;
        width: 100%;
      }
    }

    .content{
      padding-left: 12px;
      flex: 1;
      h1{
        font-size:15px;
        font-weight:bold;
        color:#333;
        line-height:18px;
      }

      &>p{
        font-size:12px;
        color:#aaa;
        line-height:18px;
        padding: 10px 0;
      }

        .price{
          font-size: 15px;
          color: #F41F1F;
          font-weight:bold;
        }
      
    }
  }

  .paymentList{
    flex: 1;
    background-color: #fff;

    .paymentItem{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 13px 0;
      padding-left: 20px;
      padding-right: 26px;
      border-bottom: 1px solid #eee;

      p{
        font-size: 15px;
        color: #333333;
        display: flex;
        align-items: center;
        
        &>span:first-child{
          font-size: 20px;
          margin-right: 10px;
        }
      }

      .weixin{
        &>span:first-child{
          color: #23BC23;
        }
      }

      .alipay{
        &>span:first-child{
          color: #09AAF4;
        }
      }
    }
  }

  .paymentButton{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 34px 27px;

    button{
      width: 100%;
      font-size:.18rem;
      color: #fff;
      background-color: #77C7D6;
      height: .48rem;
      border-radius: .24rem;
      outline: 0;
      border: 0;
    }
  }
}