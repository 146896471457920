.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  header {
    background-color: #fff;
    // display: flex;
    padding: 25px 0;
    // flex-direction: column;
    text-align: center;
    // align-items: center;

    h1 {
      font-size: 18px;
      font-weight: bold;
      color: #77c7d6;
      // line-height: 18px;
      padding-bottom: 9px;
    }

    h2 {
      font-size: 13px;
      font-weight: 500;
      color: #f6cdae;
      line-height: 18px;
    }
  }

  :global {
    .am-tabs-default-bar-top .am-tabs-default-bar-tab::after {
      display: none !important;
      // background-color: red !important;
    }

    .am-tabs-default-bar-content {
      // justify-content: space-between;
    }

    .am-tabs-default-bar-tab {
      width: auto !important;
      margin: 0 10px !important;
    }
  }

  .tabTitle {
    // white-space:nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
  }

  .courseListContainer {
    :global {
      .am-tabs-default-bar {
        background-color: transparent !important;
        // width: 56%;
        padding: 0 3%;
        // display: none !important;
        // background-color: red !important;
      }
    }

    ul {
      padding: 0 10px;

      .listItem {
        background-color: #fff;
        display: flex;
        padding: 10px;
        margin-bottom: 10px;

        .pic {
          width: 33%;
          max-height: 67px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .content {
          padding-left: 12px;
          flex: 1;
          h1 {
            font-size: 15px;
            font-weight: bold;
            color: #333;
            line-height: 18px;
          }

          & > p {
            font-size: 12px;
            color: #aaa;
            line-height: 18px;
            padding: 6px 0;
          }

          & > div {
            display: flex;
            justify-content: space-between;

            .price {
              font-size: 15px;
              color: #f41f1f;
              font-weight: bold;
            }

            .updating {
              border-radius: 2px;
              font-size: 11px;
              padding: 3px 5px;
              color: #f4a53e;
              background-color: #fff1df;
            }

            .done {
              border-radius: 2px;
              font-size: 11px;
              padding: 3px 5px;
              color:rgba(119,199,214,1);
              background:rgba(230,251,255,1);
            }

          }
        }
      }
    }
  }

  .course_tabs{
    // .am-tabs-default-bar-content{
    //   // justify-content: flex-start;
    // }
  }
}
