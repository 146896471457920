.container{
  min-height: 100%;
  display: flex;
  flex-direction: column;

  h1{
    font-size:14px;
    font-weight:bold;
    color:rgba(119,199,214,1);
    line-height:18px;
  }

  .overview{
    background-color: #fff;
    padding: 24px 14px;
    // h1{
    //   color: #77C7D6;
    // }

    p{
      color: #77C7D6;
      font-size: 14px;
      text-align: center;
      padding-top: 33px;
      padding-bottom: 20px;

      span{
        font-size: 30px;
      }
    }
  }

  .result{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 14px;

    p{
      padding-top: 16px;
      padding-bottom: 7px;
      font-size: .12rem;

      span{
        color: #77C7D6;
      }

      b{
        color:#F51717;
      }

      i{
        color:#EE9015;
      }
    }
  }

  .practise{
    padding: 20px 14px;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #fff;
    ul{
      flex: 1;
      padding: 18px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 0 -8px;
      justify-content: space-between;
      
      // &::after{
      //   content: ""; 
      //   // width: 40px; 
      //   flex:auto;
      //   // display: block; 
      //   // height:0; 
      //   // margin: 8px;
      // }

      li{
        // display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        background-color: #77C7D6;
        color: #fff;
        margin:8px;
      }

      li.empty{
        width: 40px;
        height: 40px;
        background-color: transparent;
        margin: 8;
      }

      .errorOption {
        background-color: rgba(245, 23, 23, 1);
        color: #fff;
      }

      .warningOption {
        background-color: rgba(239, 153, 52, 1);
        color: #fff;
      }

      .submit{
        background-color: rgba(119, 199, 214, 1);
        color: #fff;
      }
    }

    button{
      height:49px;
      width: 60%;
      text-align: center;
      margin:0 auto;
      background-color: #76C6D5;
      border-radius: 25px;
      color: #fff;
      border:0;
    }

  }
}
