/*介绍文字样式*/
@mixin intorText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 18px;
}

* {
  touch-action: pan-y;
}

body, #root {
  height: inherit;
  font-family: Microsoft Yahei, FZHTJW, PingFang SC, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  margin: auto;
  position: relative;
  width: 3.75rem;
  overflow: hidden;
  //margin-left: .2rem;
  //pre {
  //  white-space: pre-wrap;
  //}
  /*边图*/
  .rightBGOne {
    background: url('./../../static/img/首页/rg1.png') no-repeat;
    background-size: contain;
    width: 1.77rem;
    height: 2.7rem;
    position: absolute;
    right: -.88rem;
    top: 6.34rem;
    z-index: 2;
  }

  .rightBGTwo {
    background: url('./../../static/img/首页/rg2.png') no-repeat;
    background-size: contain;
    width: 2.58rem;
    height: 2.55rem;
    position: absolute;
    right: -1.29rem;
    top: 11.72rem;
    z-index: 2;
  }

  .rightBGThree {
    background: url('./../../static/img/首页/rg3.png') no-repeat;
    background-size: contain;
    width: 2.58rem;
    height: 2.55rem;
    position: absolute;
    right: -1.27rem;
    top: 33.5rem;
    z-index: 2;
  }

  .leftBGOne {
    background: url('./../../static/img/首页/lf1.png') no-repeat;
    background-size: contain;
    width: 1.77rem;
    height: 1.77rem;
    position: absolute;
    left: -1.06rem;
    top: 6.81rem;
    z-index: 2;
  }

  .leftBGTwo {
    background: url('./../../static/img/首页/lf2.png') no-repeat;
    background-size: contain;
    width: 1.49rem;
    height: 2.27rem;
    position: absolute;
    left: -.79rem;
    top: 11.36rem;
    z-index: 2;
  }

  .leftBGThree {
    background: url('./../../static/img/首页/lf3.png') no-repeat;
    background-size: contain;
    width: 1.49rem;
    height: 2.27rem;
    position: absolute;
    left: -.74rem;
    top: 33.7rem;
  }

  .navigate {
    width: 3.75rem;
    position: relative;
    padding-top: .09rem;
    background-color: #fff;
    height: .44rem;

    :global(.am-navbar) {
      background-color: #fff;
      height: .25rem;

      img:first-child {
        position: absolute;
        top: .16rem;
        left: .23rem;
        width: .15rem;
        height: .13rem;
      }

      img:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: .85rem;
        height: .2rem;
      }
    }

    a {
      background: url('./../../static/img/首页/dl.png') no-repeat;
      background-size: contain;
      position: absolute;
      top: .12rem;
      right: .13rem;
      width: .6rem;
      height: .2rem;
      background-color: #76c6d5;
      color: #fff;
      text-align: center;
      line-height: .2rem;
      border-radius: .02rem;
    }

    :global(.menu-drawer) {
      top: .44rem;
      height: 2rem;
      width: 3.35rem;
      margin-left: auto;
      margin-right: auto;
      -webkit-overflow-scrolling: touch;
      overflow: hidden;

      :global(.am-drawer-overlay) {
        display: none;
      }

      :global(.am-list) {
        display: flex;
        justify-content: center;
        overflow: hidden;
      }

      :global(.am-list-body) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
        align-items: center;
        width: 3.35rem;
        height: 2rem;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        border: 1px solid #E7E7E7;

        :global(.am-list-item) {
          width: 50%;
          height: .4rem;
          min-height: .4rem;
          border-bottom: 1PX solid #E7E7E7;

          :global(.am-list-content) {
            margin-left: .14rem;
          }

          :global(.am-list-line > .am-list-content) {
            font-size: .12rem;
          }

          :global(.am-list-line::after) {
            background-color: #ffffff;
          }
        }
      }

      :global(.am-drawer-sidebar > div > div > div:nth-child(1) > div.am-list-line > div) {
        color: #77C7D6;
      }
    }
  }

  .banner {
    width: 3.75rem;
    height: 1.46rem;

    :global(.swiper-container) {
      height: 1.46rem;

      :global(.swiper-pagination) {
        bottom: .05rem;

        :global(.swiper-pagination-bullet) {
          width: .12rem;
          height: .12rem;
          border: 1px solid #77C7D6;
          background: #FFFFFF;
          opacity: 1;
        }

        :global(.swiper-pagination-bullet-active) {
          background: #77C7D6;
        }
      }
    }

    //padding: 0;
    .bannerImage {
      width: 100%;
      height: 1.46rem;
      object-fit: cover;
    }

    img {
      height: 100%;
      width: 100%;
    }

    //.bannerText {
    //  position: absolute;
    //  top: 0.75rem;
    //  left: 6.13%;
    //  p {
    //    color: #fff;
    //    font-size: .2rem;
    //    padding-bottom: .1rem;
    //  }
    //}
  }

  /*精品课程*/
  .excellentCourse {
    width: 3.75rem;
    height: 5.79rem;
    background: #FFFFFF;
    //padding: 0;
    > .oneCourse {
      height: 1.27rem;

      > img {
        width: 1.2rem;
        height: .2rem;
        margin-top: .51rem;
        margin-left: 1.28rem;
      }

      > div {
        height: .26rem;
        font-weight: bold;
        font-size: .2rem;
        color: #333333;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }

    > .twoCourse {
      height: 4.52rem;

      :global(.swiper-container) {
        height: 4.52rem;
      }

      :global(.swiper-slide) {
        width: 2.46rem !important;
        height: 3.53rem;
        background: #E7F6FE;
        box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.16);
        border-radius: .02rem;

        :global(.headCo) {
          font-weight: bold;
          font-size: .2rem;
          color: #474747;
          text-align: center;
          font-style: normal;
          text-transform: none;
          height: .26rem;
          margin-top: .21rem;
          margin-bottom: .2rem;
          text-align: center;
        }

        :global(.itemContent) {
          height: .92rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: stretch;
          overflow-y: auto;

          :global(.itemS) {
            width: .85rem;
            height: .16rem;
            font-weight: 400;
            font-size: .12rem;
            color: #333333;
            text-align: center;
            font-style: normal;
            text-transform: none;
            margin-left: .25rem;
            margin-bottom: .07rem;
          }
        }

      }

      :global(.swiper-pagination) {
        bottom: .49rem;

        :global(.swiper-pagination-bullet) {
          width: .1rem;
          height: .1rem;
          border: 1px solid #77C7D6;
          background: #FFFFFF;
          opacity: 1;
        }

        :global(.swiper-pagination-bullet-active) {
          background: #77C7D6;
        }
      }
    }
  }

  /* 师资阵容 */
  .facultyLineup {
    width: 3.75rem;
    height: 5.3rem;
    background: #EEF7FF;
    //padding: 0;
    position: relative;

    .oneTeacher {
      height: 1.26rem;

      > img {
        width: 1.15rem;
        height: .2rem;
        margin-top: .5rem;
        margin-left: 1.3rem;
      }

      > div {
        height: .26rem;
        font-weight: bold;
        font-size: .2rem;
        color: #333333;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }

    .carousel {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: .4rem;

      .avatar.action {
        margin-top: 0;
        width: 1.35rem;
        height: 1.35rem;
        transition: 300ms;
      }

      .avatar {
        transition: 300ms;
        margin-top: .9rem;
        width: .6rem;
        height: .6rem;
        border-radius: 50%;
        overflow: hidden;
        background-color: rgba(16, 20, 7, 0.1);

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .info {
        opacity: 0;
        width: 3.35rem;
        margin-top: .15rem;
      }

      h2 {
        font-weight: bold;
        font-size: .2rem;
        color: #333333;
        line-height: .25rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }

      h3 {
        font-weight: bold;
        font-size: .14rem;
        color: #333333;
        line-height: .23rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
        white-space: pre-wrap;
      }

      h4 {
        font-size: .12rem;
        color: #aaa;
        padding-top: .05rem;
      }
    }

    :global(.slider) {
      height: 2.99rem !important;
    }

    :global(.am-carousel-wrap-dot>span) {
      border: 1px solid #77C7D6;
      background: #EEF7FF !important;
    }

    :global(.am-carousel-wrap-dot-active>span) {
      background: #77C7D6 !important;
    }

    & > button {
      width: .6rem;
      height: .2rem;
      background: url('./../../static/img/首页/more.png') no-repeat;
      background-size: contain;
      border: 0;
      position: absolute;
      left: 1.58rem;
      bottom: .5rem;
    }
  }

  /*合格率/合格学员*/
  .passRate {
    width: 3.75rem;
    height: 8.57rem;
    background: #FFFFFF;

    .onePass {
      height: 1.26rem;

      > img {
        width: 2.43rem;
        height: .2rem;
        margin-top: .5rem;
        margin-left: .66rem;
      }

      > div {
        height: .26rem;
        font-weight: bold;
        font-size: .2rem;
        color: #333333;
        //line-height: 0px;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }

    .twoPass {
      height: 2.5rem;

      :global(.slider) {
        width: 1.67rem !important;
        height: 2.5rem !important;
        margin-left: .2rem;
        float: left;

        .imgPass {
          height: 2.5rem !important;

          > img {
            width: 1.67rem;
            height: 2.5rem;
          }
        }

        :global(.slider-decorator-0) {
          bottom: .15rem !important;
        }

        :global(.am-carousel-wrap-dot>span) {
          border: 1px solid #77C7D6;
          background: #FFFFFF !important;
        }

        :global(.am-carousel-wrap-dot-active>span) {
          background: #77C7D6 !important;
        }
      }

      .textPass {
        width: 1.6rem;
        height: 2.5rem;
        float: left;
        margin-left: .12rem;
        font-weight: 400;
        font-size: .08rem;
        //color: #070707;
        line-height: .114rem;
        text-align: right;
        font-style: normal;
        text-transform: none;
      }
    }

    .thrPass {
      width: 3.35rem;
      height: 4.14rem;
      background: #C8E9F7;
      border-radius: .08rem;
      margin-top: .35rem;
      margin-left: .2rem;
      position: relative;

      :global(.slider) {
        height: 3.49rem !important;
        //margin-top:.36rem;
        .carousel {
          display: flex;
          flex-direction: column;
          margin-top: .36rem;
          margin-left: .31rem;

          .stuItem {
            width: 2.74rem;
            height: 1.17rem;
            background: #FFFFFF;
            box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.16);
            margin-bottom: .3rem;

            img {
              width: 1.24rem;
              height: 1.17rem;
              float: left;
            }

            > div {
              width: 1.5rem;
              height: 1.17rem;
              float: left;
              padding-left: .1rem;
              position: relative;

              .intro {
                width: 1.1rem;
                height: .19rem;
                font-weight: bold;
                font-size: .14rem;
                color: #595959;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-top: .19rem;
                margin-bottom: .1rem;
              }

              .school {
                width: 1.29rem;
                height: .16rem;
                font-weight: 400;
                font-size: .12rem;
                color: #595959;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-top: .09rem;
                margin-bottom: .08rem;
              }

              .profession {
                width: 1.29rem;
                height: .16rem;
                font-weight: 400;
                font-size: .12rem;
                color: #595959;
                text-align: left;
                font-style: normal;
                text-transform: none;
              }

              .button {
                position: absolute;
                top: .18rem;
                right: .1rem;
                width: .6rem;
                height: .2rem;
                border-radius: .04rem;
                border: 1px solid #77C7D6;
                padding: .03rem .1rem;

                > div {
                  width: .4rem;
                  height: .14rem;
                  font-weight: 400;
                  font-size: .1rem;
                  color: #77C7D6;
                  //letter-spacing: .28rem;
                  text-align: center;
                  font-style: normal;
                  text-transform: none;
                }
              }
            }
          }
        }

        :global(.am-carousel-wrap-dot>span) {
          border: 1px solid #77C7D6;
          background: #C8E9F7 !important;
        }

        :global(.am-carousel-wrap-dot-active>span) {
          background: #77C7D6 !important;
        }
      }

      .more {
        width: .6rem;
        height: .2rem;
        background: url('./../../static/img/首页/more.png') no-repeat;
        background-size: contain;
        border: 0;
        position: absolute;
        left: 1.38rem;
        bottom: .3rem;
      }
    }
  }

  /*特色服务*/
  .historicalEvolution {
    width: 3.75rem;
    height: 5.25rem;
    background: #EDF6FF;

    .oneEvolution {
      height: 1.26rem;

      > img {
        width: 1.2rem;
        height: .2rem;
        margin-top: .5rem;
        margin-left: 1.28rem;
      }

      > div {
        height: .26rem;
        font-weight: bold;
        font-size: .2rem;
        color: #333333;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }

    .twoEvolution {
      height: 3.99rem;
      position: relative;

      .carousel {
        .hisItem {
          width: 3.35rem;
          height: 1.37rem;
          background: #FFFFFF;
          border-radius: .04rem;
          margin-left: .2rem;
          margin-bottom: .3rem;

          .hisLeft {
            width: 1.08rem;
            height: 1.37rem;
            background: #C8E9F7;
            border-radius: .04rem;
            float: left;

            > img {
              width: .5rem;
              height: .5rem;
              margin-left: .29rem;
              margin-top: .2rem;
            }

            .hisName {
              width: 1.08rem;
              height: .19rem;
              font-weight: bold;
              font-size: .14rem;
              color: #333333;
              //line-height: .25rem;
              text-align: center;
              font-style: normal;
              text-transform: none;
            }
          }

          .hisRight {
            width: 2.27rem;
            height: 1.37rem;
            float: left;
            font-weight: 400;
            font-size: .12rem;
            color: #333333;
            line-height: .22rem;
            text-align: left;
            font-style: normal;
            text-transform: none;
            padding: .17rem .2rem .16rem .2rem;
          }
        }
      }

      :global(.slider) {
        height: 3.99rem !important;

        :global(.slider-decorator-0) {
          bottom: .5rem !important;
        }
      }

      :global(.am-carousel-wrap-dot>span) {
        border: 1px solid #77C7D6;
        background: #EDF6FF !important;
      }

      :global(.am-carousel-wrap-dot-active>span) {
        background: #77C7D6 !important;
      }
    }
  }

  /* 原创教科书 */
  .latestActivities {
    width: 3.75rem;
    height: 3.54rem;
    background: #FFFFFF;
    //padding: 0;
    .oneActive {
      height: 1.26rem;

      > img {
        width: 2.79rem;
        height: .2rem;
        margin-top: .5rem;
        margin-left: .48rem;
      }

      > div {
        height: .26rem;
        font-weight: bold;
        font-size: .2rem;
        color: #333333;
        //line-height: 0rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }

    .carousel {
      //padding: .1rem;
      padding-left: .2rem;
      //background-color: #fff;
      img {
        width: 3.35rem;
        height: 1.33rem;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      }
    }

    :global(.slider) {
      height: 2.28rem !important;

      :global(.slider-decorator-0) {
        bottom: .5rem !important;
      }
    }

    :global(.am-carousel-wrap-dot>span) {
      border: 1px solid #77C7D6;
      background: #FFFFFF !important;
    }

    :global(.am-carousel-wrap-dot-active>span) {
      background: #77C7D6 !important;
    }
  }

  /*为什么选择东稻学院*/
  .whyChoose {
    width: 3.75rem;
    height: 4.44rem;
    background: #EEF7FF;
    //padding: 0;
    .oneWhy {
      height: 1.26rem;

      > img {
        width: 3.4rem;
        height: .2rem;
        margin-top: .5rem;
        margin-left: .18rem;
      }

      > div {
        height: .26rem;
        font-weight: bold;
        font-size: .2rem;
        color: #333333;
        //line-height: 0px;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }

    .carousel {
      //padding: .1rem;
      padding-left: .2rem;
      //background-color: #fff;
      img {
        width: 3.35rem;
        height: 2.23rem;
        //box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      }
    }

    :global(.slider) {
      height: 3.18rem !important;

      :global(.slider-decorator-0) {
        bottom: .5rem !important;
      }
    }

    :global(.am-carousel-wrap-dot>span) {
      border: 1px solid #77C7D6;
      background: #EEF7FF !important;
    }

    :global(.am-carousel-wrap-dot-active>span) {
      background: #77C7D6 !important;
    }
  }

  /* 最新活动 */
  .newActivities {
    width: 3.75rem;
    height: 3.96rem;
    background: #FFFFFF;
    //padding: 0;
    .oneNew {
      height: 1.26rem;

      > img {
        width: .76rem;
        height: .2rem;
        margin-top: .5rem;
        margin-left: 1.5rem;
      }

      > div {
        height: .26rem;
        font-weight: bold;
        font-size: .2rem;
        color: #333333;
        //line-height: 0rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }

    .carousel {
      padding-left: .39rem;
      position: relative;

      img {
        width: 2.98rem;
        height: 1.75rem;
        max-width: unset;
      }

      .title {
        position: absolute;
        bottom: 0;
        width: 2.98rem;
        height: .30rem;
        background: #77C7D6;
        padding-left: .09rem;
        padding-top: .07rem;
        font-weight: 400;
        font-size: .12rem;
        color: #FBFCF9;
        //line-height: .24rem;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    :global(.slider) {
      height: 2.7rem !important;

      :global(.slider-decorator-0) {
        bottom: .5rem !important;
      }
    }

    :global(.am-carousel-wrap-dot>span) {
      border: 1px solid #77C7D6;
      background: #FFFFFF !important;
    }

    :global(.am-carousel-wrap-dot-active>span) {
      background: #77C7D6 !important;
    }
  }

  /*校区地址*/
  .businessOutlets {
    width: 3.75rem;
    height: 4.31rem;
    background: #EEF7FF;
    //padding: 0;
    .oneBusiness {
      height: 1.26rem;

      > img {
        width: 1.22rem;
        height: .2rem;
        margin-top: .5rem;
        margin-left: 1.27rem;
      }

      > div {
        height: .26rem;
        font-weight: bold;
        font-size: .2rem;
        color: #333333;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
    }

    :global(.swiper-slide) {
      width: 2.98rem !important;
      height: 2.1rem !important;

      .content {
        width: 2.98rem;
        height: .77rem;
        background: #FFFFFF;
        padding-top: .1rem;

        .title {
          width: 2.88rem;
          margin-left: .05rem;
          float: left;
          text-align: left;
          font-style: normal;
          text-transform: none;
          line-height: .2rem;

          .yuanxiao {
            width: 2.29rem;
            height: .19rem;
            font-weight: 400;
            font-size: .14rem;
            color: #4D5057;
          }

          .address {
            width: 2.78rem;
            height: .14rem;
            font-weight: 400;
            font-size: .1rem;
            color: #9B9B9B;
            line-height: .26rem;
          }

          .tel {
            width: 2.78rem;
            height: .14rem;
            font-weight: 400;
            font-size: .1rem;
            color: #9B9B9B;
            line-height: .36rem;
          }
        }
      }
    }

    :global(.swiper-container) {
      height: 3.05rem;

      :global(.swiper-pagination) {
        bottom: .5rem;
      }
    }

    :global(.swiper-pagination-bullet) {
      width: .1rem;
      height: .1rem;
      border: 1px solid #77C7D6;
      background: #EEF7FF;
      opacity: 1;
    }

    :global(.swiper-pagination-bullet-active) {
      background: #77C7D6;
    }
  }

  /*合作伙伴*/
  .cooperativePartner {
    width: 3.75rem;
    height: 1.07rem;
    background: #FFFFFF;
    padding-top: .3rem;
    position: relative;

    .onePartner {
      float: left;
      margin-left: .2rem;
      width: .68rem;
      height: .47rem;
      background: #89C6D5;
      //border-radius: .04rem;
      color: #FFFFFF;
      text-align: center;
      font-style: normal;
      text-transform: none;
      padding-top: .09rem;
      //padding-left: .1rem;
      .one {
        //width: .49rem;
        height: .16rem;
        font-weight: bold;
        font-size: .12rem;
      }

      .two {
        //width: .44rem;
        margin-top: .02rem;
        height: .13rem;
        font-weight: 400;
        font-size: .08rem;
        text-align: left;
        margin-left: .12rem;
      }
    }

    .twoPartner {
      float: left;
      margin-left: .1rem;
      width: 2.57rem;
      height: .47rem;

      :global(.swiper-slide) {
        width: .94rem !important;
        height: .47rem;
        margin-right: .1rem;

        > img {
          width: .94rem;
          height: .45rem;
          border: 0.2px solid #707070;
        }
      }
    }

    .thrPartner {
      position: absolute;
      top: .3rem;
      right: 0;
      background: #FFFFFF;
      width: .5rem;
      z-index: 1;

      .bp {
        margin-left: .1rem;
        width: .2rem;
        height: .47rem;

        > img {
          width: .2rem;
          height: .47rem;
        }
      }
    }
  }

  /*底部*/
  .footer {
    width: 3.75rem;
    background: url('./../../static/img/首页/ft_bg.png') no-repeat;
    background-size: contain;
    position: relative;
    height: 1.95rem;

    .fzzpng {
      height: 1.95rem;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .title {
      width: 1.7rem;
      float: left;

      > img {
        width: .98rem;
        height: .38rem;
        margin-top: .21rem;
        margin-left: .2rem;
      }
    }

    .content {
      float: left;
      width: 2.05rem;
      position: relative;
      margin-top: .21rem;

      .right {
        height: .2rem;
        margin-bottom: .14rem;

        > img {
          width: .18rem;
          height: .2rem;
          float: left;
        }

        > div {
          margin-left: .1rem;
          height: .14rem;
          font-weight: 400;
          font-size: .10rem;
          color: #9B9B9B;
          line-height: .2rem;
          text-align: left;
          font-style: normal;
          text-transform: none;
          float: left;
        }
      }
    }
  }
}
