.container {
  // touch-action: none;
  height: 100vh;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 29px;
    padding-bottom: 21px;
    background-color: #fff;
    border-bottom: 1px solid #eee;

    h1 {
      font-size: 18px;
      font-weight: bold;
      color: rgba(119, 199, 214, 1);
      line-height: 18px;
      padding-bottom: 9px;
    }

    h2 {
      font-size: 13px;
      font-weight: 500;
      color: rgba(246, 205, 174, 1);
      line-height: 18px;
    }
  }

  ul {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 48%;
      padding: 8px;
      background-color: #fff;
      margin-bottom: 10px;

      .imgContainer {
        width: 100%;
        min-height: 101px;
        text-align: center;
        // background-color: #ddd;

        img {
          height: 101px;
          // width: 100%;
        }
      }

      h2 {
        color: #333;
        font-size: 15px;
        font-weight: bold;
        line-height: 18px;
        padding: 7px 0;
      }

      p {
        font-size: 12px;
        font-weight: 500;
        color: #999;
        line-height: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      span{
        font-size: 12px;
        font-weight: 500;
        color: #999;
        line-height: 18px;
      }
    }
  }
  .moreCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    .more {
      border: 1px solid rgba(238, 238, 238, 1);
      border-radius: 5px;
      font-weight: 500;
      color: rgba(119, 199, 214, 1);
      background-color: #fff;
      line-height: 18px;
      font-size: 12px;
      width: 96px;
      height: 34px;
      margin-top: 23px;
      margin-bottom: 29px;
    }
  }
}
