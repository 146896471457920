.nModifyPhoneContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
  background: #F6F9F9;

  .nickNameWrap {
    flex-grow: 2;
  }

  .buttonWrap {
    text-align: center;
    height: .49rem;
    margin-bottom: .19rem; 
  
    span {
      line-height: .49rem;
    }
  }

  .captchaBtn {
    font-size: .15rem;
    color: rgb(119, 199, 214);
    padding-right: 1.07%;
  }

  .phoneNumber {
    padding-left: 5.6%;
    color: rgb(153, 153, 153);
  }

  .captchaInput {
    padding: 0 !important;
    
    input {
      font-size: .15rem !important;
      color: rgb(204, 204, 204) !important;
    }
  }
}

.phoneNumberInput { 
  display: inline-block !important;
  max-width: 64.22%;
  line-height: 44px;

  div {
    padding-right: 0px !important;
  }
}

