.msgDetailsContainer {
 padding: .11rem 2.67%;
 background-color: rgba(244, 246, 249, 1);
 min-height: 100%;

 .msgDetailsContent{
   padding: .18rem 4% 35px 4%;
   background-color: #fff;

   h1 {
    font-size: 15px;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: rgba(73,85,94,1);
    padding-bottom: .11rem;
   }

   .date {
    font-size: 12px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: rgba(153,153,153,1);
    padding-bottom: .23rem;
   }

   .msgInfo {
    font-size: 12px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: rgba(153,153,153,1);
    padding-bottom: .23rem;
    line-height: 22px;
   }

   .msgHref {
    font-size: 12px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: rgba(119, 199, 214, 1);
   }

   .imgWrap {
     width: 100%;
    margin-top: .19rem;

     img {
       width: 100%;
       height: auto
     }
   }
 }
}