.courseManagementContainer {
  min-height: 100%;
  background-color: rgba(246, 249, 249, 1);
  .header {
    display: flex;
    flex-direction: column;
    height: 1.17rem;
    background-color: #fff;
    margin-bottom: .1rem;

    h1 {
      padding: .23rem 0 .19rem 5.07%;
      font-size: 18px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: rgba(51,51,51,1);
    }
  }

  .searchInputWrap {
    width: 88%;
    align-self: center;
  }

  .courseManagementContent {
    .course {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      height: .92rem;
      margin-bottom: 1px;
      padding: .17rem 5.73% .16rem 5.73%;

      h2 {
        font-size: 15px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(51,51,51,1);
        padding-bottom: .13rem;
        line-height: 14px;
      }

      p {
        font-size: 12px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(153,153,153,1);
        line-height: 12px;
      }
      
      .schoolArea {
        padding-bottom: .08rem;
        line-height: 13px;
      }
    }
  }
}