/*咨询解答*/
.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    text-align: center;
    padding: 25px 0;
    padding-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #EFEFEF;

    h1 {
      font-size: 18px;
      font-weight: bold;
      color: rgba(119, 199, 214, 1);
      line-height: 18px;
      padding-bottom: 9px;
    }

    h2 {
      font-size: 13px;
      color: rgba(246, 205, 174, 1);
      line-height: 18px;
    }

    div {
      display: flex;
      justify-content: flex-end;
      padding: 20px;

      button {
        background-color: dodgerblue;
        padding: 7px 17px;
        outline: 0;
        border: 0;
        color: #fff;
        border-radius: 5px;

      }
    }
  }

  .noData{
    flex: 1;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 10px 13px;
    padding-bottom: 25px;
    p{
      text-align: center;
    }
  }

  ul {
    flex: 1;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 10px 13px;
    padding-bottom: 25px;

    li {
      border-bottom: 1px solid #EFEFEF;
      font-size: 15px;
      color: #333;
      padding: 10px 0;
      line-height: 30px;
    }
  }
}