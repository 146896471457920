.msgListContainer {
  min-height: 100%;
  background-color: rgba(246, 249, 249, 1);
  display: flex;
  flex-direction: column;

  .header {
    height: .62rem;
    padding-top: .23rem;
    padding-left: 5.07%;
    background-color: #fff;

    h1 {
      font-size: 18px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: rgba(51,51,51,1);
    }
  }

  .content {
   .msgDateWrap {
     padding-top: .2rem;

     .msgDate {
       background-color: rgba(218, 223, 229, 1);
       width: 20.8%;
       height: .18rem;
       text-align: center;
       border-radius: .03rem;
       margin: 0 auto;
       margin-bottom: .11rem;

       p {
        font-size: 12px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(255,255,255,1);
        line-height: .18rem;
       }
     }

     .msg {
       display: flex;
       align-items: center;
       margin: 0 .1rem;
       height: .85rem;
       background-color: #fff;
       border-radius: .05rem;
       padding: .18rem 3.24%;
       margin-bottom: .1rem;

       .msgIconWrap {
         i {
           font-size: .47rem;
           color: rgba(119, 199, 214, 1);
         }
       }

       .msgInfoWrap {
         padding-left: 2.93%;
         h3 {
          font-size: 15px;
          font-family: PingFang-SC-Bold;
          font-weight: bold;
          color: rgba(73,85,94,1);
         }

         p {
          font-size: 12px;
          font-family: PingFang-SC-Medium;
          font-weight: 500;
          color: rgba(153,153,153,1);
          padding-top: .09rem;
         }
       }
     }
   } 
  }
}