.modifyNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
  background: #F6F9F9;

  .nickNameWrap {
    flex-grow: 2;
  }

  .buttonWrap {
    text-align: center;
    height: .49rem;
    margin-bottom: .19rem; 
  
    span {
      line-height: .49rem;
    }
  }

}

