.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 30px;
    background-color: #fff;

    h1 {
      font-size: 18px;
      font-weight: bold;
      color: rgba(119, 199, 214, 1);
      line-height: 18px;
      padding-bottom: 9px;
    }

    h2 {
      font-size: 13px;
      color: rgba(246, 205, 174, 1);
      line-height: 18px;
    }
  }

  .addrList {
    background-color: #fff;
    padding: 0 20px;
    // padding-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: bold;
    color: #999;
    line-height: 16px;
    overflow-x: scroll;
    li{
      // width: 25%;
      margin:0 8px;
      padding-bottom: 16px;
    }

    li.active{
      color: #77C7D6;
    }
  }

  .contactContent{
    margin:10px;
    margin-bottom: 0;
    background-color: #fff;
    flex: 1;
    padding: 22px;
    padding-top: 17px;
    display: flex;
    flex-direction: column;

    .contact{
      border-bottom: 1px solid #efefef;
      padding-bottom: 20px;
 
      div{
        display: flex;
        align-items: top;
        line-height: 24px;
        padding: 5px 0;
        i{
          font-size: 14px;
          color: #77C7D6;
        }

        p{
          font-size: 14px;
          color: #333;
          padding-left: 9px;
        }
      }
    }

    .mapBox{
      // flex: 1;
      padding: 26px 3px;
      .map{
        background-color: #77C7D6;
        padding: 4px;
        border-radius: 5px;
        height: 1.88rem;
        img{
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}