.examAnalyseContainer {
  min-height: 100%;
  background: #fff;

  .title {
    display: flex;
    justify-content: space-between;
    height: .44rem;
    padding: .24rem 9.87% .08rem 4.8%;
    font-size: .13rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: rgba(119,199,214,1);

    p {
    height: .13rem;
    }
    .currentQuestionNumber {
      font-size: .15rem;
      color:rgba(246, 205, 174, 1);
    }
  }

  .question {
    width: 100%;

    .questionContent {
      font-size: .14rem;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(51,51,51,1);
      line-height: .24rem;
      padding: .08rem 5.6% .28rem 4.8%;
    }

    .options {
      padding: 0 6.93% .26rem 6.93%;

      .option {
        display: flex;
        min-height: .25rem;
        margin-bottom: .2rem;

        &:last-child {
          margin: 0;
        }
        .optionSign {
          box-sizing: content-box;
          flex-shrink: 0;
          text-align: center;
          width: 25px;
          height: 25px;
          margin-right: 4.8%;
          flex-basis: 25px;
          font-size: .13rem;
          font-family: PingFang-SC-Medium;
          font-weight: 500;
          color: rgba(204,204,204,1);
          line-height: 25px;
          border: 1px solid rgba(204, 204, 204, 1);
          border-radius: 50%;
        }

        .optionContent {
          font-size: 14px;
          font-family: PingFang-SC-Medium;
          font-weight: 500;
          color: rgba(51,51,51,1);
          line-height: 25px;
        }
      }
    }
  }

  .separatorLine {
    width: 91.2%;
    margin: 0 auto;
    height: 1px;
    background-color: rgba(238, 238, 238, 1);

  }

  .answer {
    padding-top: .23rem;
    padding-left: 7.47%;
    font-size:14px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: rgba(51,51,51,1);
  }

  .handle {
    display: flex;
    justify-content: space-around;
    margin-top: .31rem;
    width: 100%;
    height: 35px;
  }

  .answerCardWrap {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #cccccc61;

    .whiteBlock {
      height: 100%;
      z-index: -1;
    }
    
    
    .answerCard {
      overflow: auto;
      position: absolute;
      bottom: 0;
      height: 2.75rem;
      width: 100%;
      background-color: #fff;
      padding: .29rem 0;
      display: flex;
      flex-direction: column;

      h3 {
        font-size: 14px;
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        color: rgba(119,199,214,1);
        line-height: 18px;
        padding-bottom: .28rem;
        padding-left: 4%;
      }

      .opsiontsWrap {
        display: flex;
        flex-wrap: wrap;
        padding-left: 2%;  
        padding-right: 2%;  

        .options {
          width: 16.6%;
          height: .39rem;        
          line-height: .39rem;
          text-align: center;
          margin-bottom: .16rem;
          color: #fff;

          p {
            width: .39rem;
            height: .39rem;
            border-radius: 50%;
            background-color: rgba(119, 199, 214, 1);
            margin: 0 auto;
          }

          .errorOption {
            background-color: rgba(245, 23, 23, 1);
          }

          .warningOption {
            background-color: rgba(239, 153, 52, 1);
          }
        }
      }
    }
  }
}

/*
错误答案
 */
.wrongOption {
  .optionSign {
    background-color: rgba(245, 23, 23, 1);
    color: rgba(255, 255, 255, 1) !important;
    border-color: red !important;
  }
}

/* 
正确答案案
 */
.rightOption {
  .optionSign {
    background-color: rgba(119, 199, 214, 1);
    color: rgba(255, 255, 255, 1) !important;
    border-color: rgba(119, 199, 214, 1) !important;
  }
}

.active {
  display: block !important;
}