.container{
  background-color: #F8F9FC;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  header{
    background-color: #fff;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 13px;
    h1{
      font-size:18px;
      font-weight:bold;
      color:#77C7D6;
      padding-bottom: 9px;
    }
    h2{
      font-size:13px;
      font-weight:500;
      color:#F6CDAE;
    }
  }

  ul{
    display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 40px;
      flex-wrap: wrap;
      padding: .1rem;
      flex: 1;

      img{
        // width: 100%;
        height: 108px;
      }
  }

  .stuItem {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .07rem;
    border: 1px solid rgba(238, 238, 238, 1);
    width: 48%;
    background-color: #fff;
    margin-bottom: 10px;

    &>p {
      font-size: 11px;

      font-weight: 500;
      color: rgba(153, 153, 153, 1);
      line-height: 18px;
    }

    &>button {
      border: 1px solid rgba(119, 199, 214, 1);
      background-color: #fff;
      font-size: 12px;
      font-weight: 500;
      color: rgba(119, 199, 214, 1);
      line-height: 18px;
      margin-top: 12px;
      margin-bottom: 10px;
      border-radius: 2px;
      // width:61px;
      // height:23px;
    }
  }

  .intro {
    display: flex;
    align-items: baseline;
    padding-top: 8px;

    h3 {
      font-size: 17px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 18px;
      padding-right: 5px;
    }

    p {
      font-size: 12px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(153, 153, 153, 1);
      line-height: 18px;
    }
  }

}