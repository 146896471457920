.serviceManagementContainer {
  min-height: 100%;
  background-color: rgba(246, 249, 249, 1);
  display: flex;
  flex-direction: column;

  .header {
    height: 0.62rem;
    padding-top: 0.23rem;
    padding-left: 5.07%;
    background-color: #fff;
    margin-bottom: 0.1rem;

    h1 {
      font-size: 18px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
  }

  .content {
    flex: 2;

    .course {
      display: flex;
      align-items: center;
      height: 0.73rem;
      margin-bottom: 0.01rem;
      padding-top: 0.17rem;
      padding-left: 5.73%;
      background: #fff;

      .checkbox {
        margin-right: 20px;
      }
      h3 {
        font-size: 15px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        padding-bottom: 0.12rem;
        line-height: 0.14rem;
      }

      p {
        font-size: 12px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(153, 153, 153, 1);
      }
    }
  }

  .buttonWrap {
    width: 100%;
    text-align: center;
    margin: 0.25rem 0;
  }
}
