.container {
  header {
    text-align: center;
    background-color: #fff;
    padding-top: 25px;
    padding-bottom: 14px;

    h1 {
      font-size: 18px;
      font-weight: bold;
      color: #77C7D6;
      padding-bottom: 9px;
    }

    h2 {
      font-size: 13px;
      font-weight: 500;
      color: #F6CDAE;
    }
  }

  &>div {
    background-color: #fff;
    padding: 20px 28px 10px 13px;
    margin: 10px;

    &>h1 {
      text-align: center;
      font-size: 16px;
      color: #333;
      font-weight: bold;
      padding-bottom: 20px;
    }

    .explain {
      display: flex;

  

      &>p:first-child {
        width: 15%;
        font-size: 14px;
        font-weight: 500;
        color: #333;
      }

      &>pre:last-child {
        white-space: pre-wrap;
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        color: #666;
        line-height: 18px;
      }
    }

    .exam_type {
      // padding-left: 15%;
      padding-top: 29px;
      padding-bottom: 12px;

      padding-top: 29px;
      padding-bottom: 12px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      &>:first-child {
        // margin-right: 30px;
      }

      button {
        background-color: #fff;
        border: 1px solid #77C7D6;
        width: 38%;
        height: 35px;
        border-radius: 3px;
        outline: 0;
        color: #fff;
        color: #77C7D6;
        margin-bottom: 10px;
      }

      &::after {
        content: "";
        width: 38%;
      }

      .active {
        background-color: #77C7D6;
        color: #fff;
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 15%;

      li {
        width: 50%;
        font-size: 14px;
        font-weight: 500;
        color: #666;
        line-height: 18px;
        padding-bottom: 12px;

        span {
          padding-right: 10px;
        }
      }
    }
  }
}