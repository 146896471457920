.container{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 29px;
    padding-bottom: 21px;
    background-color: #fff;
    border-bottom: 1px solid #eee;

    h1{
      font-size:18px;
      font-weight:bold;
      color:rgba(119,199,214,1);
      line-height:18px;
      padding-bottom: 9px;
    }

    h2{
      font-size:13px;
      font-weight:500;
      color:rgba(246,205,174,1);
      line-height:18px;
    }
  }

  .listContainer{
    background-color: #fff;
    padding: 2px 10px;
    margin-bottom: 10px;
    flex: 1;

    &>:nth-child(4){
      border: 0 !important;
    }

    .listItem{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid #efefef;
      align-items: center;
      padding: 14px 0;

      .pic{
        width: 30.67%;
        height: 100%;
        max-height: 80px;
        img{
          width: 100%;
          // height: 100%;
          max-height: 80px;
        }
      }

      .content{
        padding-left: 10px;
        flex: 1;

        h1{
          font-size:17px;
          font-weight:bold;
          color:rgba(51,51,51,1);
          line-height:25px;
        }

        p{
          font-size:12px;
          font-weight:500;
          color:rgba(153,153,153,1);
          line-height:25px;
        }
      }
    }
  }
}