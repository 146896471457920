.examResultContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: rgba(246, 249, 249, 1);  

  .header {
    height: 1.45rem;
    background-color: rgba(255, 255, 255, 1);
    margin-bottom: .1rem;
    
    .titleDateWrap {
      display: flex;
      justify-content: space-between;
      padding-top: .24rem;

      .title {
        font-size: .13rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(119,199,214,1);
        padding-left: 3.87%;
      }

      .date {
        font-size: .11rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(153,153,153,1);      
        padding-right: 3.07%;
      }
    }

    .total {
      color: rgba(119, 199, 214, 1);
      font-size: .13rem;
      padding-top: .39rem;
      text-align: center;

      .point {
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        font-size: .3rem;
        line-height: .18rem;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding-left: 4%;
    background-color: #fff;
    height: 1.25rem;

    .contentTitle {
      padding-top: .17rem;
      font-size: .14rem;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: rgba(119,199,214,1);
    }

    .examHeadcount {
      padding-top: .15rem;
      font-size: .14rem;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(51,51,51,1);
    }

    .pointAnalyse {
      display: flex;
      justify-content: space-between;    
      padding-top: .15rem;  
      padding-right: 6.67%;
    }
  }

  .buttonWrap {
    text-align: center;
    height: .49rem;
    margin-bottom: .19rem;   
    position: absolute;    
    bottom: 0;
    width: 100%;
    span {
      line-height: .49rem;
    }
  }
}