/**
*  基础列表样式  引入时加上容器className
*/
.feedbackContainer,
.consultListContainer,
.reimbursementContainer,
.rollcallContainer,
.capacityAssessContainer,
.tAccountSafety,
.nAccountSafety, 
.nModifyPhoneContainer, 
.modifyNameContainer,
.personContainer,
.myCenterContainer {
  .am-list-body {
    border: none;
     &::before {
      height: 0 !important;
    }

    &::after {
      height: 0 !important;      
    }
  }

  .am-list-item .am-list-line .am-list-content {
    font-size: .15rem;
  }

  .am-list-item {
    box-sizing: border-box;
    padding-left: 5.07%;
    height: .50rem;   
    border-bottom: 1px solid rgba(246, 249, 249, 1);

    .am-list-line {
      padding-right: .15rem;
      &::after{
        background-color: #EEEEEE !important;
        height: 0 !important
      }
      .am-list-extra {
        font-size: .15rem;
        color: rgba(153, 153, 153, 1);        
      }
      border: none !important;
    }

  }
  .functionList {
    .iconfont {
      font-size: .14rem;  
      margin-left: 2.13%;
      font-weight: bold;
    }

    .listIeam {
      margin-left: 2.67%;
    }
  }

  // .am-list-extra {
  //   text-align: left !important;
  // }
}

.myCenterContainer {
  .am-list-item {
    padding-left: 2.11%; 
  }
}

.personContainer {
  .am-list-body {
    .am-list-item {
      &:first-child {
        height: .65rem;
      }
    }

    .personAvatra {
      border-radius: 50%;
      height: .45rem;
      width: .45rem;
    }
  }
}

.modifyNameContainer {
  .am-list-item .am-input-control input {
    font-size: .15rem;
    color: rgba(153, 153, 153, 1);
  }
}

.am-button.fullBtn {
  height: 100%;
  width: 88%;
  font-size: .15rem;
  border-radius: .25rem .24rem .24rem .24rem !important;
  span{
    // line-height: 100%;

  }
}


.am-button.ghostButton {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  // padding: 0;
  border: 1px solid #77C7D6 !important;
  border-radius: 5px !important;
}

.rollcallContainer {
  .am-list-line {
    justify-content: space-between;
    .am-list-content {
      flex: 1;
    }
    .am-list-extra {
      flex: 1;
      text-align: left !important;
      flex-basis: auto !important;
    }
    .am-list-arrow am-list-arrow-vertical {
      flex: 1;
    }
  }
}

/* 
咨询列表
 */
 .consultListContainer {
   .am-list-extra {
     font-size: 12px !important;
   }

   .consultContent {
     .am-list-content {
      font-size: 15px;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: rgba(51,51,51,1);
      line-height: 18px;
    }
   }
 }
/* 
 咨询详情
  */
  .consultDetailsContainer { 
    .blockButton {
      height: .49rem;
      width: 43.66%;
    }
  }
/* 
意见反馈  
 */

.feedbackContainer {
  .am-textarea-item {
    height: auto;
    padding-left: 5.07% !important;
    border-top: 1px solid rgba(246, 249, 249, 1);
    margin-bottom: .1rem;

    .am-textarea-control textarea {
      font-size: 15px;
    }
  }

  .am-list-body {
    &>.am-list-item {
      padding-left: 0;
    }

    .am-input-item {
      padding-left: 5.07%;
    }

    .phoneInput {
      input {
        font-size: 15px;
      }
    }
  }
}
/* 
费用报销
 */
.reimbursementContainer {

  .moneyAndPic {
    margin-bottom: .1rem;
  }
  
  .am-textarea-item {
    height: auto;
    padding-left: 5.07% !important;
    border-top: 1px solid rgba(246, 249, 249, 1);

    .am-textarea-control textarea {
      font-size: 15px;
    }
  }

  .am-list-body {
    &>.am-list-item {
      padding-left: 5.07%;
    }

    .am-list-extra {
      color: rgba(204, 204, 204, 1) !important;  
    }

    .am-input-item {
      padding-left: 0;
    }

    .phoneInput {
      input {
        font-size: 15px;
      }
    }
  }

  .invoiceUpload {
    height: auto;

    .am-list-line .am-list-content {
      padding-top: .17rem;
      padding-bottom: .17rem;
      font-size:15px;
      font-family:PingFang-SC-Medium;
      font-weight:500;
      color:rgba(204,204,204,1);
      line-height:18px;
      
      .am-image-picker {
        .am-image-picker-list {
          padding: 0;
          padding-top: .24rem;
          margin: 0;
          
          .am-flexbox .am-flexbox-align-center {
            margin:0;
          }
        }
      }

      .am-image-picker-list .am-flexbox {
        margin-bottom: 0 !important;
        height: .38rem;

        
        .am-image-picker-list .am-image-picker-upload-btn {
          background-color: black
        }
      }

      .am-image-picker-list .am-image-picker-upload-btn {
        height: .38rem;
        width: .38rem;
        border: none;
        border-radius: 0;
        background-color: rgba(238, 238, 238, 1);
        
        &:before {
          height: .16rem;
          background-color: rgba(153, 153, 153, 1);
        }

        &:after {
          width: .16rem;
          background-color: rgba(153, 153, 153, 1);
        }

      }
    }
  }
}

.capacityAssessContainer {

  .am-list-item  {
    height: auto !important;
    padding-left: 19px !important;
  }

  .am-list-content {
    color: rgba(153, 153, 153, 1) !important;
  }
}

.modifyNameContainer {
  .phoneInputWrap {
    .am-list-line {
      .am-list-extra {
        flex-basis: 47%;
      }
    }
  }
}
.capacityAssessContainer {
  .am-list-line {
    .am-list-line{
      padding-right: 0px !important;
    }
    input {
      text-align: right;
    }  
  }
  .half{
    .am-list-line {
      .am-list-extra {
        flex-basis: 65%;
      }
    }
  }
  .half1{
    .am-list-line {
      .am-list-extra {
        flex-basis: 50%;
      }
    }
  } 
   .half2{
    .am-list-line {
      .am-list-extra {
        flex-basis: 40%;
      }
    }
  }
}