.myCenterContainer {
  background: #F6F9F9;
  height: 100%;
}
.header {
  width: 100%;
  height: 1.45rem;
  background: rgb(255,255,255);
  display: flex;
  align-items: center;

.avatarWrap {
  width: .72rem;
  height: .72rem;
  border-radius: 50%;
  border: .03rem solid #EEEEEE;
  margin: 0 3.87% 0 4.93%; 
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}

  .userInfo {
    display: inherit;
    justify-content: flex-start;
    flex-direction: column;  

    .userName {
      color: #333333;
      font-size: .18rem;
      padding-bottom: .14rem;
    }

    .sexIcon {
      display: inline-block;
      font-size: .14rem;
      height: .18rem;
      vertical-align: middle;
      margin-left: 10px;
    }

    .sexMale {
      color: rgba(61, 156, 245, 1);
    }

    .sexFemale {
      color: rgba(255, 68, 156, 1);
    }


    .movingCommunication {
      display: inline-flex;      
      font-size: .12rem;

      i {
        font-size: .12rem;
      }
      .wxCode {
        flex: 1;
        align-self: flex-end;
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
      }
    }
  }
}

.functionList {
  min-height: 3.96rem;
  margin: .12rem 2.76% .18rem 2.76%;
  overflow: hidden;
}

.buttonWrap {
  text-align: center;
  height: .49rem;
  margin-bottom: .19rem;

  span {
    line-height: .49rem;
  }
}

